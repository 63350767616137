import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";
import FormComponent from "../components/ui/FormComponent";
import { PriceCardComponent } from "../components/ui/PriceCard";

export default function MaintenancePage({ data }) {
  return (
    <Layout
      title="Разовые работы по сайту"
      description="Разовые работы по сайту цена. Разовые работы по сайту заказать"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Разовые работы по сайту цена</TitleComponent>
        <MarginComponent small="sm:mt-5" margin="mt-4">
          <PriceCardComponent
            data={data.list.nodes}
            price={data.price.nodes[0]}
          />
        </MarginComponent>
      </MarginComponent>
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Разовые работы по сайту заказать</TitleComponent>
        <FormComponent />
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    list: allSanityList(
      filter: { cat: { eq: "razovye-raboty-po-sajtu" } }
      sort: { order: ASC, fields: _createdAt }
    ) {
      nodes {
        id
        name
      }
    }
    price: allSanityPrice(
      filter: { slug: { current: { eq: "razovye-raboty-po-sajtu" } } }
    ) {
      nodes {
        id
        name
        cost
        days
        slug {
          current
        }
      }
    }
  }
`;
